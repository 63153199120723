import {
  AppBar,
  IconButton,
  Link as MuiLink,
  Stack,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { altFontFamily, headerLinks } from "../../common";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { useColourMode } from "../../hooks";
import styled from "@emotion/styled";

export default function Header() {
  const theme = useTheme();
  const { lightMode, setLightMode } = useColourMode();

  const FancyTooltip = styled(({ className, ...props }: any) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      boxShadow: theme.shadows[1],
      padding: "1em",
      fontSize: 14,
    },
  }));

  return (
    <AppBar
      position="fixed"
      enableColorOnDark
    >
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        alignItems="center"
        sx={{ height: "3em" }}
      >
        {headerLinks.map((title) => {
          return (
            <MuiLink
              key={title}
              component={Link}
              to={`#${title}`}
              sx={{
                fontFamily: altFontFamily,
                textTransform: "uppercase",
                fontWeight: "900",
                color: theme.palette.primary.contrastText,
              }}
            >
              {title}
            </MuiLink>
          );
        })}
        <FancyTooltip title="Switch Themes" placement="bottom">
          <IconButton
            color="secondary"
            onClick={() => setLightMode(!lightMode)}
          >
            {lightMode ? <LightbulbIcon /> : <LightbulbOutlinedIcon />}
          </IconButton>
        </FancyTooltip>
      </Stack>
    </AppBar>
  );
}
