import {
  Divider,
  Stack,
  Tooltip,
  tooltipClasses,
  Button,
  Typography,
  MenuList,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useColourMode } from "../../hooks";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import { useNavigate } from "react-router-dom";
import { headerLinks } from "../../common";
import PetsIcon from "@mui/icons-material/Pets";

interface CoreMenuProps {
  onClick?: () => void;
}

export const drawerWidth = 240;

const FancyTooltip = styled(({ className, ...props }: any) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[1],
    padding: "1em",
    fontSize: 14,
  },
}));

export default function CoreMenu({ onClick }: CoreMenuProps) {
  const navigate = useNavigate();
  const { lightMode, setLightMode } = useColourMode();

  return (
    <>
      <MenuList>
        {headerLinks.map((title, index) => {
          return (
            <Stack
              key={title}
              direction="row"
              spacing={2}
              onClick={() => {
                navigate(`#${title}`);
                onClick && onClick();
              }}
              sx={{
                padding: "0.5em",
                justifyContent: "space-between",
              }}
            >
              <PetsIcon
                color={index % 2 === 0 ? "primary" : "secondary"}
                fontSize="small"
              />
              <Typography>{title}</Typography>
              <PetsIcon
                color={index % 2 === 0 ? "primary" : "secondary"}
                fontSize="small"
              />
            </Stack>
          );
        })}
      </MenuList>
      <Divider variant="middle" />
      <Stack>
        <FancyTooltip title="Switch Themes" placement="right">
          <Button color="secondary" onClick={() => setLightMode(!lightMode)}>
            {lightMode ? <LightbulbIcon /> : <LightbulbOutlinedIcon />}
          </Button>
        </FancyTooltip>
      </Stack>
    </>
  );
}
