import { createTheme } from "@mui/material";
import { altFontFamily, primaryFontFamily } from "../common";

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#240090",
    },
    secondary: {
      main: "#FF652F",
    },
    background: {
      default: "#19181A",
      paper: "#19181A",
    },
    text: {
      primary: "#F4F4F4",
    },
    divider: "#F4F4F4",
  },
  typography: {
    h1: {
      fontSize: "3em",
      fontFamily: altFontFamily,
      fontWeight: "900",
      textTransform: "uppercase",
    },
    h2: {
      fontSize: "2.4em",
      fontFamily: altFontFamily,
      fontWeight: "900",
      textTransform: "uppercase",
    },
    h3: {
      fontSize: "2em",
      fontFamily: altFontFamily,
      fontWeight: "900",
      textTransform: "uppercase",
    },
    h4: {
      fontSize: "1.6em",
      fontFamily: altFontFamily,
      fontWeight: "900",
      textTransform: "uppercase",
    },
    h5: {
      fontSize: "1.2em",
      fontFamily: altFontFamily,
      fontWeight: "900",
      textTransform: "uppercase",
    },
    h6: {
      fontSize: "1em",
      fontFamily: altFontFamily,
      fontWeight: "900",
      textTransform: "uppercase",
    },
    button: {
      fontWeight: "bold",
    },
    fontFamily: primaryFontFamily,
    fontSize: 16,
  },
  spacing: 8,
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiCard: {
      defaultProps: { elevation: 4 },
      styleOverrides: {
        root: {
          borderRadius: "12px",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          minHeight: "100vh",
        },
      },
    },
  },
});

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#4056a1",
    },
    secondary: {
      main: "#f76c6c",
    },
    background: {
      default: "#F9FFF9",
      paper: "#fff",
    },
    text: {
      primary: "#000",
    },
    divider: "#000",
  },
  typography: {
    h1: {
      fontSize: "3em",
      fontFamily: altFontFamily,
      fontWeight: "900",
      textTransform: "uppercase",
    },
    h2: {
      fontSize: "2.4em",
      fontFamily: altFontFamily,
      fontWeight: "900",
      textTransform: "uppercase",
    },
    h3: {
      fontSize: "2em",
      fontFamily: altFontFamily,
      fontWeight: "900",
      textTransform: "uppercase",
    },
    h4: {
      fontSize: "1.6em",
      fontFamily: altFontFamily,
      fontWeight: "900",
      textTransform: "uppercase",
    },
    h5: {
      fontSize: "1.2em",
      fontFamily: altFontFamily,
      fontWeight: "900",
      textTransform: "uppercase",
    },
    h6: {
      fontSize: "1em",
      fontFamily: altFontFamily,
      fontWeight: "900",
      textTransform: "uppercase",
    },
    button: {
      fontWeight: "bold",
    },
    fontFamily: primaryFontFamily,
    fontSize: 16,
  },
  spacing: 8,
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiCard: {
      defaultProps: { elevation: 4 },
      styleOverrides: {
        root: {
          borderRadius: "12px",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          minHeight: "100vh",
        },
      },
    },
  },
});
