import {
  AppBar,
  Drawer,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import CoreMenu from "./CoreMenu";
import { primaryFontFamily } from "../../common";

export default function MobileHeader() {
  const theme = useTheme();
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <AppBar
        position="fixed"
        enableColorOnDark
        sx={{
          borderRadius: 0,
        }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <MenuIcon />
          </IconButton>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              alignItems: "center",
              justifyContent: "center",
              flexGrow: 1,
            }}
          >
            <Typography
              variant="h4"
              fontWeight="bold"
              fontFamily={primaryFontFamily}
              color={theme.palette.primary.contrastText}
            >
              CATS
            </Typography>
          </Stack>
        </Toolbar>
        <Drawer open={menuOpen} onClose={() => setMenuOpen(false)}>
          <Stack sx={{ padding: "2em 1em 0 1em" }}>
            <CoreMenu onClick={() => setMenuOpen(false)} />
          </Stack>
        </Drawer>
      </AppBar>
    </>
  );
}
