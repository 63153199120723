import { ColourModeProvider } from "./hooks";
import { ParallaxProvider } from "react-scroll-parallax";
import { RouterProvider } from "react-router-dom";
import { routes } from "./Routes";
import { ServiceWorkerUpdate } from "./components";

function App() {
  return (
    <ColourModeProvider>
      <ParallaxProvider>
        <ServiceWorkerUpdate>
          <RouterProvider router={routes} />
        </ServiceWorkerUpdate>
      </ParallaxProvider>
    </ColourModeProvider>
  );
}

export default App;
