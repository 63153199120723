import { Stack, Theme, useMediaQuery } from "@mui/material";
import { BasicProps } from "../../common/interfaces";
import Header from "../header/Header";
import MobileHeader from "../header/MobileHeader";

export default function Layout({ children }: BasicProps) {
  const isTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );
  return (
    <>
      {isTablet ? (
        <>
          <MobileHeader />
          <Stack sx={{overflowX:'clip'}}>{children}</Stack>
        </>
      ) : (
        <>
          <Header />
          <Stack sx={{ padding: "2em 0" }}>{children}</Stack>
        </>
      )}
    </>
  );
}
