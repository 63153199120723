import { Stack, useMediaQuery } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";

interface PageContainerProps {
  includeHeight?: boolean;
  children: string | JSX.Element | JSX.Element[];
  sx?: SxProps<Theme>;
}

export default function PageContainer({
  includeHeight = true,
  sx = {},
  children,
}: PageContainerProps) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const isTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("lg")
  );

  const margin = isMobile
    ? includeHeight
      ? "2em 0"
      : "1em 0"
    : isTablet
    ? includeHeight
      ? "2em 2em"
      : "1em"
    : includeHeight
    ? "2em 10em"
    : "1em 10em";

  return (
    <Stack
      spacing={2}
      sx={[{ margin: margin }, ...(Array.isArray(sx) ? sx : [sx])]}
    >
      {children}
    </Stack>
  );
}
