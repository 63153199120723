import { CssBaseline, ThemeProvider, useMediaQuery } from "@mui/material";
import { createContext, useContext, useState } from "react";
import { darkTheme, lightTheme } from "../theme/theme";
import { BasicProps } from "../common/interfaces";

interface ColourModeProps {
  lightMode: boolean;
  setLightMode: (lightMode: boolean) => void;
}

const ColourModeContext = createContext<ColourModeProps>({
  lightMode: true,
  setLightMode: () => {
    return;
  },
});

export const useColourMode = () => useContext(ColourModeContext);

const lightModeName = "lightMode";

export function ColourModeProvider({ children }: BasicProps) {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [lightMode, setLightMode] = useState(getSavedColorMode());

  function getSavedColorMode() {
    const existing = localStorage.getItem(lightModeName);
    if (existing) {
      return existing === "true";
    }

    return !prefersDarkMode;
  }

  function saveColorMode(mode: boolean) {
    setLightMode(mode);
    localStorage.setItem(lightModeName, mode.toString());
  }

  return (
    <ColourModeContext.Provider
      value={{ lightMode, setLightMode: saveColorMode }}
    >
      <ThemeProvider theme={lightMode ? lightTheme : darkTheme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ColourModeContext.Provider>
  );
}
