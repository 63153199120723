import {
  Card,
  CardContent,
  CardMedia,
  Stack,
  Typography,
  useMediaQuery,
  Theme,
} from "@mui/material";
import { PageContainer } from "../../components";
import { Parallax } from "react-scroll-parallax";
import ranga from "../../assets/images/ranga.jpg";
import fidget from "../../assets/images/fidget.jpg";
import teenie from "../../assets/images/teenie.jpg";
import { normalTransition, primaryFontFamily } from "../../common";
import { useRef } from "react";
import { useInView } from "framer-motion";

export function WhoAreCats() {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const data = [
    {
      name: "Fidget",
      nickname: "Fidge",
      title: "The Old Lady",
      description: (
        <>
          <Typography>
            Fidget has the meow of a cat who smoked twenty packets a day as a
            kitten.
          </Typography>
          <Typography>
            Boo Bear (the previous family dog) found her in the wilds, calling
            with her scraggly voice. Once captured, we found out she'd been in
            the wild for 3 years! And her old family were immigrating to New
            Zealand that week!
          </Typography>
          <Typography>
            So, I took her in. Now, she enjoys sleeping, glad to have a pillow
            instead of the dusty Australia Outback.
          </Typography>
        </>
      ),
      image: fidget,
      imageAlt: "A picture of a black and white old lady cat named Fidget.",
    },
    {
      name: "Albert",
      nickname: "Ranga",
      title: "The Ginger Clown",
      description: (
        <>
          <Typography>
            Albert is a trickster, a pure ginger monster whose entire existence
            is to annoy and scream.
          </Typography>
          <Typography>
            I got him when he was a kitten, scooped him up as a companion for
            Fidget, but the age difference proved a challenge, and I spent a lot
            of time entertaining him!
          </Typography>
          <Typography>
            Today, he is the most needy of the three, constantly begging for
            attention, scratching and destroying things, and sniffing shoes (he
            may have a problem).
          </Typography>
        </>
      ),
      image: ranga,
      imageAlt: "A picture of a ginger cat named Albert.",
    },
    {
      name: "Rita",
      nickname: "Teenie",
      title: "The Gargantuan Baby",
      description: (
        <>
          <Typography>
            Rita is the largest. I got her when she was a speck, hence the
            nickname.
          </Typography>
          <Typography>
            Then, she grew up into the monstrosity she is today. My theory is
            she siphoned some of Albert's strength, but unfortunately, she
            didn't take any of his confidence. She's a veritable scaredy cat,
            eager to hide from anyone that's not me.
          </Typography>
          <Typography>
            But when I'm home alone, she's super affectionate.
          </Typography>
        </>
      ),
      image: teenie,
      imageAlt: "A picture of a white and orange giant cat named Rita.",
    },
  ];

  function CatCard({ cat, index }: any) {
    const ref = useRef(null);
    const isInView = useInView(ref);
    return (
      <Card
        key={cat.name}
        ref={ref}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: isMobile ? "100%" : `${(1 / data.length) * 100}%`,
          transform: isInView ? "none" : "translateX(-200px)",
          opacity: isInView ? 1 : 0,
          transition: normalTransition(isMobile ? 0 : index),
        }}
      >
        <CardContent sx={{ flex: "1 0", padding: "2em" }}>
          <Stack spacing={2}>
            <Stack>
              <Typography
                variant={isMobile ? "h4" : "h3"}
              >{`${cat.name} aka ${cat.nickname}`}</Typography>
              <Typography
                variant="h5"
                color="text.secondary"
                fontFamily={primaryFontFamily}
              >
                {cat.title}
              </Typography>
            </Stack>
            <Stack>{cat.description}</Stack>
          </Stack>
        </CardContent>
        <CardMedia
          component="img"
          sx={{ width: isMobile ? 400 : "100%" }}
          image={cat.image}
          alt={cat.imageAlt}
        />
      </Card>
    );
  }

  return (
    <>
      <PageContainer includeHeight={false}>
        <Stack spacing={1} sx={{ padding: isMobile ? "0 1em" : "0 2em" }}>
          <Typography
            id="Cats"
            variant={isMobile ? "h3" : "h2"}
            sx={{ scrollMargin: "2em" }}
          >
            Who are these Cats?
          </Typography>
        </Stack>
      </PageContainer>
      <PageContainer includeHeight={false}>
        <Parallax speed={10}>
          <Stack direction={isMobile ? "column" : "row"} spacing={2}>
            {data.map((cat, index) => {
              return <CatCard key={cat.name} cat={cat} index={index} />;
            })}
          </Stack>
        </Parallax>
      </PageContainer>
    </>
  );
}
