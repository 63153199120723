import { Stack, Theme, alpha, useMediaQuery } from "@mui/material";

interface BackgroundContainerProps {
  image: string;
  children?: string | JSX.Element | JSX.Element[];
  backgroundPosition?: string;
}

export default function BackgroundContainer({
  image,
  children,
  backgroundPosition,
}: BackgroundContainerProps) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );
  return (
    <Stack
      sx={{
        height: "25em",
        boxShadow: `inset 0 0 1em 1em ${alpha("#222", 0.5)}`,
        backgroundImage: `url(${image})`,
        backgroundAttachment: isMobile ? "scroll" : "fixed",
        backgroundPosition: backgroundPosition ?? "center",
        backgroundRepeat: "no-repeat",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
    </Stack>
  );
}
