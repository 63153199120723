import {
  Stack,
  Typography,
  useMediaQuery,
  Theme,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  IconButton,
  StepIconProps,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import { PageContainer } from "../../components";
import BreakfastDiningIcon from "@mui/icons-material/BreakfastDining";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import LiquorIcon from "@mui/icons-material/Liquor";
import { useRef, useState } from "react";
import { useInView } from "framer-motion";
import { normalTransition } from "../../common";
import dinner from "../../assets/images/dinner.jpg";
import dryFood from "../../assets/images/dry-food.jpg";
import breakfast from "../../assets/images/breakfast.jpg";
// import greenies from "../../assets/images/greenies.jpg";

export function WhatEat() {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const [step, setStep] = useState(0);

  const data = [
    {
      index: 0,
      label: "In the morning",
      Icon: <BreakfastDiningIcon color="primary" />,
      description: (
        <Stack spacing={1}>
          <Typography>
            In the morning, whenever I wake up, I give them some real meat! This
            will be a few chunks (4-5) per bowl. Ranga and Fidget will eat it,
            Teenie is not fussed.
          </Typography>
          <Typography>
            Leave it out on the white cat mat, as Fidge and Ranga will come back
            and eat throughout the day.
          </Typography>
        </Stack>
      ),
      images: [{ image: breakfast, alt: "A picture of kangaroo chunks in three cat bowls." }],
    },
    {
      index: 1,
      label: "In the evening",
      Icon: <FastfoodIcon color="primary" />,
      description: (
        <Stack spacing={1}>
          <Typography>
            In the evening, whenever I return from work, I give them some pet
            meat in their bowls. This'll alternate between:
          </Typography>
          <Typography>(1) 2/3rds of a Fussy Cat block, and...</Typography>
          <Typography>
            (2) 1/3rd of a Fussy Cat block and a pouch of wet food.
          </Typography>
        </Stack>
      ),
      images: [{ image: dinner, alt: "A picture of wet cat food." }],
    },
    {
      index: 2,
      label: "Before bed",
      Icon: <LiquorIcon color="primary" />,
      description: (
        <Stack spacing={1}>
          <Typography>
            Before bed, I refill their dry bowls with just enough to cover the
            bottom. No more, or they'll get fat! Then, I give them the treat
            biscuits!
          </Typography>
          <Typography>
            Ranga gets 3, Teenie gets 2, Fidget <b>0</b>! No matter how much Fidge
            begs, and pleads, and cries, don't give her any! Any more and she'll
            puke it back up!
          </Typography>
        </Stack>
      ),
      images: [
        { image: dryFood, alt: "A picture of dried cat food in two bowls." },
        //{ image: greenies, alt: "A picture of Greenies cat treats." },
      ],
    },
  ];

  function StepperIcon(props: StepIconProps, Icon: JSX.Element, index: number) {
    const { active } = props;
    return (
      <IconButton
        color={active ? "primary" : "secondary"}
        onClick={() => setStep(index)}
      >
        {Icon}
      </IconButton>
    );
  }

  function CatCard({ cat, index }: any) {
    const ref = useRef(null);
    const isInView = useInView(ref);

    return (
      <Card
        key={cat.index}
        ref={ref}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: isMobile ? "100%" : `${(1 / data.length) * 100}%`,
          opacity: isInView ? 1 : 0,
          transition: normalTransition(index),
        }}
      >
        <CardContent sx={{ flex: "1 0", padding: "2em" }}>
          <Stack spacing={2}>
            <Typography variant="h5">
              {cat.Icon}
              {"  "}
              {cat.label}
            </Typography>
            <Stack>{cat.description}</Stack>
          </Stack>
        </CardContent>
        {cat.images.map((image: { image: string; alt: string }) => {
          return (
            <CardMedia
              key={image.alt}
              component="img"
              sx={{ width: isMobile ? 400 : "100%" }}
              image={image.image}
              alt={image.alt}
            />
          );
        })}
      </Card>
    );
  }

  return (
    <>
      <PageContainer includeHeight={false}>
        <Stack spacing={1} sx={{ padding: isMobile ? "0 1em" : "0 2em" }}>
          <Typography id="Food" variant={isMobile ? "h3" :"h2"} sx={{ scrollMargin: "2em" }}>
            What do they eat?
          </Typography>
        </Stack>
      </PageContainer>
      <PageContainer includeHeight={false}>
        <Stack spacing={2} sx={{ padding: isMobile ? "0 1em" : "0 2em" }}>
          <Typography>
            The beasts have a mixed diet of pure meat, cheap meat, cheaper meat,
            and dried food.
          </Typography>
          {isMobile ? (
            <>
              <Typography>
                Tap the icons to see a larger description.
              </Typography>
              <Stepper activeStep={step} orientation={"vertical"}>
                {data.map((cat) => {
                  return (
                    <Step key={cat.index}>
                      <StepLabel
                        StepIconComponent={(props) => {
                          return StepperIcon(props, cat.Icon, cat.index);
                        }}
                      >
                        {cat.label}
                      </StepLabel>
                      <StepContent>{cat.description}</StepContent>
                    </Step>
                  );
                })}
              </Stepper>
            </>
          ) : (
            <Stack spacing={4} direction="row" justifyContent="space-between">
              {data.map((cat, index) => {
                return <CatCard key={cat.index} cat={cat} index={index} />;
              })}
            </Stack>
          )}
        </Stack>
      </PageContainer>
    </>
  );
}
