import { Chip, Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import { PageContainer } from "../../components";
import { useInView } from "framer-motion";
import { useRef, useState } from "react";
import { normalTransition } from "../../common";
import groundDiagram from "../../assets/images/ground-diagram.png";
import { useColourMode } from "../../hooks";

export function Bins() {
  const firstRef = useRef(null);
  const firstIsInView = useInView(firstRef);
  const secondRef = useRef(null);
  const secondIsInView = useInView(secondRef);
  const thirdRef = useRef(null);
  const thirdIsInView = useInView(thirdRef);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const { lightMode } = useColourMode();

  function MobileImage() {
    const [big, setBig] = useState(false);
    return (
      <>
        {/* <Typography>Tap it to enlarge.</Typography> */}
        <img
          src={groundDiagram}
          alt="A diagram of the apartment car park"
          onClick={() => setBig(!big)}
          style={{
            transition: normalTransition(0),
            height: big ? "fit-content" : "auto",
            width: big ? "fit-content" : "auto",
            backgroundColor: lightMode ? "none" : "#ddd",
          }}
        />
      </>
    );
  }

  return (
    <>
      <PageContainer includeHeight={false}>
        <Stack spacing={1} sx={{ padding: isMobile ? "0 1em" : "0 2em" }}>
          <Typography id="Bins" variant={isMobile ? "h3" :"h2"} sx={{ scrollMargin: "2em" }}>
            Where does the rubbish go?
          </Typography>
        </Stack>
      </PageContainer>
      <PageContainer includeHeight={false}>
        <Stack sx={{ padding: isMobile ? "0 1em" : "0 2em" }}>
          <Stack
            ref={firstRef}
            spacing={1}
            sx={{
              transform: firstIsInView ? "none" : "translateX(-200px)",
              opacity: firstIsInView ? 1 : 0,
              transition: normalTransition(0),
            }}
          >
            <Typography>The rubbish goes in the bin!</Typography>
            <Typography>
              Here is a terrible diagram of the apartment complex's ground
              floor.
            </Typography>
          </Stack>
          <Stack
            ref={secondRef}
            spacing={1}
            sx={{
              transform: secondIsInView ? "none" : "translateX(200px)",
              opacity: secondIsInView ? 1 : 0,
              transition: normalTransition(0),
            }}
          >
            {isMobile ? (
              <MobileImage />
            ) : (
              <img
                src={groundDiagram}
                alt="A diagram of the apartment car park"
                style={lightMode ? {} : { backgroundColor: "#ddd" }}
              />
            )}
          </Stack>
          <Stack
            ref={thirdRef}
            spacing={1}
            sx={{
              transform: thirdIsInView ? "none" : "translateX(-200px)",
              opacity: thirdIsInView ? 1 : 0,
              transition: normalTransition(0),
            }}
          >
            <Typography>
              The bin area is in the far corner that is <b>not</b> near the
              parking space for your car. You'll need your buzzer to get in.
            </Typography>
            <Typography>The bins are easy to understand.</Typography>
            <Stack direction="row" spacing={1}>
              <Chip color="error" />
              <Typography>
                Red is for <b>general waste</b>, so put the bin nearest the
                front door in them. I do this 1-2 times a week. Also put the
                used cat litter in here.
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Chip color="warning" />
              <Typography>
                Yellow is for <b>recycling</b>, so put the box of recycling in
                the pantry in these bins. I do this 1-2 times a week.
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Chip color="success" />
              <Typography>
                Green is for <b>organic waste</b>, so put the little bin in the
                pantry in this bin. I do this every other day, as that little
                bin gets stinky quickly.
              </Typography>
            </Stack>
            <Typography>
              Also note where the storage room is. It should have the apartment
              number on its door, and that is where the spare cat litter is.
            </Typography>
          </Stack>
        </Stack>
      </PageContainer>
    </>
  );
}
