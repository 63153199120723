import { Stack, Theme, Typography, useMediaQuery } from "@mui/material";
import { PageContainer } from "../../components";
import dayjs from "dayjs";
import { motion, useInView } from "framer-motion";
import { useRef } from "react";
import { normalTransition } from "../../common";
import { Link } from "react-router-dom";

export function DoTheyPoop() {
  const today = dayjs();
  const referenceDate = dayjs("2024-05-12");
  const daysBetween = today.diff(referenceDate, "day");
  const threeCheck = daysBetween % 3;
  const message =
    threeCheck === 0
      ? "YES, you need to clean out the litter tray!"
      : threeCheck === 2
      ? `no, you have 1 more day until they need a full clean.`
      : `no, you have 2 more days until they need a full clean.`;

  const ref = useRef(null);
  const isInView = useInView(ref);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  return (
    <>
      <PageContainer includeHeight={false}>
        <Stack spacing={1} sx={{ padding: isMobile ? "0 1em" : "0 2em" }}>
          <Typography
            id="Litter"
            variant={isMobile ? "h3" : "h2"}
            sx={{ scrollMargin: "2em" }}
          >
            Do they poop?
          </Typography>
        </Stack>
      </PageContainer>
      <PageContainer includeHeight={false}>
        <Stack
          ref={ref}
          spacing={4}
          sx={{
            padding: isMobile ? "0 1em" : "0 2em",
            transform: isInView ? "none" : "translateX(200px)",
            opacity: isInView ? 1 : 0,
            transition: normalTransition(0),
          }}
        >
          <Stack spacing={1}>
            <Typography
              component={motion.p}
              animate={{ fontWeight: 400 } as any}
              initial={{ fontWeight: 900 } as any}
              transition={{
                duration: 0.5,
                repeatType: "reverse",
                repeat: Infinity,
              }}
            >
              Yes, they do!
            </Typography>
            <Typography>
              There are two litter trays, one for each bathroom. A full
              clean-out should occur every <b>three</b> days.
            </Typography>
            <Typography>
              They are filled with the litter bag kept in the office room
              cupboard. When that gets empty, bring another one up from the
              storage cupboard downstairs (see{" "}
              <Link to="#Bins">the Bins section</Link> for a map).
            </Typography>
            <Typography>
              {
                "So, if they were cleaned out on Sunday, they'd go Sunday -> Wednesday -> Saturday -> Tuesday -> Friday -> Monday -> Thursday -> Sunday -> and so on."
              }
            </Typography>
          </Stack>
          <Stack spacing={1}>
            <Typography variant="h5">
              Do the litter trays need cleaning out today?
            </Typography>
            <Typography>
              {`Today is ${today.format("DD/MM/YYYY")}, which means that `}
              <b>{message}</b>
            </Typography>
            <Typography>
              There are bin liners and wet wipes under the kitchen sink.
            </Typography>
            <Typography>
              When not doing a full clean, there are black poop bags you can
              use. Chuck them in the bin, and take the bins out when
              appropriate.
            </Typography>
          </Stack>
        </Stack>
      </PageContainer>
    </>
  );
}
