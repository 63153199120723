import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { PageContainer } from "../../components";
import { useInView } from "framer-motion";
import { useRef } from "react";
import { normalTransition } from "../../common";
import PetsIcon from "@mui/icons-material/Pets";

export function Tips() {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const tricks = [
    "I'll provide a way of submitting issues with the caretaker outside of this website.",
    "If one of them gets zoomies, chances are they've pooped!",
    "If they kick cat litter everywhere, there's a green dustpan/brush in the hallway cupboard!",
    "If Fidget throws up, there's carpet cleaner under the kitchen sink!",
    "Every morning, when you make the bed, Ranga will jump up and likes a fuss!",
    "There are feathers-on-sticks above the oven, on top of the kitchen cupboards!",
    "The games consoles are free to use. The Xbox password is 123456.",
  ];

  return (
    <>
      <PageContainer includeHeight={false}>
        <Stack spacing={1} sx={{ padding: isMobile ? "0 1em" : "0 2em" }}>
          <Typography
            id="Tips"
            variant={isMobile ? "h3" : "h2"}
            sx={{ scrollMargin: "2em" }}
          >
            Tips and Tricks
          </Typography>
        </Stack>
      </PageContainer>
      <PageContainer includeHeight={false}>
        <Stack
          ref={ref}
          sx={{
            padding: isMobile ? "0" : "0 2em",
            transform: isInView ? "none" : "translateX(200px)",
            opacity: isInView ? 1 : 0,
            transition: normalTransition(0),
          }}
        >
          <List>
            {tricks.map((trick, index) => {
              return (
                <ListItem key={trick}>
                  <ListItemAvatar>
                    <Avatar>
                      <PetsIcon
                        color={index % 2 === 0 ? "primary" : "secondary"}
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={trick} />
                </ListItem>
              );
            })}
          </List>
        </Stack>
      </PageContainer>
    </>
  );
}
