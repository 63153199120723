import {
  Card,
  CardContent,
  Divider,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  BackgroundContainer,
  Bins,
  DoTheyPoop,
  PageContainer,
  Tips,
  WhatEat,
  WhoAreCats,
} from "../../components";
import frontCover from "../../assets/images/catsBanner.jpg";
import teenieSpook from "../../assets/images/teenie-spook.jpg";
import fidgeRelax from "../../assets/images/fidge-relax.jpg";
import rangaRelax from "../../assets/images/ranga-relax.jpg";
import { useLocation } from "react-router-dom";
import { useEffect, useRef } from "react";
import PetsIcon from "@mui/icons-material/Pets";

export function Home() {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const location = useLocation();
  const lastHash = useRef("");

  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: "smooth", block: "start" });
        lastHash.current = "";
      }, 100);
    }
  }, [location]);

  return (
    <>
      {isMobile && <Stack sx={{ height: "2em" }} />}
      <BackgroundContainer image={frontCover} backgroundPosition="50% -10%">
        <PageContainer>
          <Card
            id="Home"
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              scrollMargin: "12em",
            }}
          >
            <CardContent
              sx={{ padding: isMobile ? "1em" : "2em", textAlign: "center" }}
            >
              <Typography variant={isMobile ? "h2" : "h1"}>
                How to Look After Fidget, Ranga, and Teenie
              </Typography>
            </CardContent>
          </Card>
        </PageContainer>
      </BackgroundContainer>
      <PageContainer>
        <Divider>
          <PetsIcon color="secondary" fontSize="large" />
        </Divider>
      </PageContainer>
      <WhoAreCats />
      <PageContainer>
        <Divider>
          <PetsIcon color="primary" fontSize="large" />
        </Divider>
      </PageContainer>
      <WhatEat />
      <PageContainer>
        <Divider>
          <PetsIcon color="secondary" fontSize="large" />
        </Divider>
      </PageContainer>
      <BackgroundContainer
        image={teenieSpook}
        backgroundPosition={isMobile ? "64% 30%" : "center"}
      />
      <PageContainer>
        <Divider>
          <PetsIcon color="primary" fontSize="large" />
        </Divider>
      </PageContainer>
      <DoTheyPoop />
      <PageContainer>
        <Divider>
          <PetsIcon color="secondary" fontSize="large" />
        </Divider>
      </PageContainer>
      <Bins />
      <PageContainer>
        <Divider>
          <PetsIcon color="primary" fontSize="large" />
        </Divider>
      </PageContainer>
      <BackgroundContainer
        image={fidgeRelax}
        backgroundPosition={isMobile ? "68% 30%" : "center"}
      />
      <PageContainer>
        <Divider>
          <PetsIcon color="secondary" fontSize="large" />
        </Divider>
      </PageContainer>
      <Tips />
      <PageContainer>
        <Divider>
          <PetsIcon color="primary" fontSize="large" />
        </Divider>
      </PageContainer>
      <BackgroundContainer
        image={rangaRelax}
        backgroundPosition={isMobile ? "40% 35%" : "center"}
      />
      <PageContainer>
        <Divider>
          <PetsIcon color="secondary" fontSize="large" />
        </Divider>
      </PageContainer>
      <PageContainer>
        <Stack
          spacing={1}
          sx={{ padding: isMobile ? "0 1em 7em" : "0 2em 7em" }}
        >
          <Typography
            id="Bins"
            variant={isMobile ? "h3" : "h2"}
            sx={{ scrollMargin: "2em" }}
          >
            Thanks for looking after the cats!
          </Typography>
        </Stack>
      </PageContainer>
    </>
  );
}
